import {securedBackend, unsecuredBackend} from "../../../api";
import {backendUrls, securityUrls} from "../../../api/urls";
import {
    ApiResponse,
    AuthUserResponse,
    LoginUserRequest,
    userForm
} from "../../../types";
import {UserInfo} from "../../../domain/domain";

export const registerUser = async (payload: userForm) => unsecuredBackend
    .post<ApiResponse<AuthUserResponse>>(securityUrls.auth.REGISTER_USER, payload);
export const updateProfileUser = async (payload: userForm) => unsecuredBackend
    .post<ApiResponse<AuthUserResponse>>(securityUrls.auth.UPDATE_PERSONAL_USER_INFO, payload);

export const loginUser = async (payload: LoginUserRequest) => unsecuredBackend
    .post<ApiResponse<AuthUserResponse>>(securityUrls.auth.LOGIN, payload);
export const getLoggedUser = async (userId: number) => securedBackend
    .get<ApiResponse<UserInfo>>(backendUrls.users.LOGGED_USER(userId));
