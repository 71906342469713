import type {AxiosRequestConfig} from 'axios'
import axios from 'axios'
import appConfig from '../config'
import {
    clearLocalUser,
    clearTokens,
    getExpiresAt,
    getJWT,
    getRefreshToken,
    setExpiresInSeconds,
    setJWT,
    setRefreshToken
} from '../storage/local.storage'
import {ApiResponse, JWTResponse} from '../types'
import type {AuthUrlType} from './urls'
import {securityUrls} from './urls'
import {store} from "../store";
import {openModal} from "../store/features/slices/modal";

export const apiBase = 'api/v0'
export const securedBackend = axios.create()
export const unsecuredBackend = axios.create()

const prepareUrl = (url: string) => ((url?.startsWith('/')) ? url.substring(1) : url)

securedBackend.interceptors.request.use((config: AxiosRequestConfig): any => {
    const jwt = getJWT();

    if (!jwt) {
        //store.dispatch(openModal({modal:'login'}));
        return
    }
    return {
        ...
            config,
        url: `${appConfig?.backend.server_url}/${apiBase}/${prepareUrl(config.url ?? '')}`,
        headers: {
        authorization: `Bearer ${getJWT()}`, ...
        (config?.headers ?? {})
        }
    }
})

unsecuredBackend.interceptors.request.use((config: AxiosRequestConfig): any => ({
    ...config,
    url: `${appConfig?.backend.server_url}/${apiBase}/${prepareUrl(config.url ?? '')}`,
}))

export const isTokenNearExpiry = () => {
    const nearExp = 15 * 60 * 1000
    return (Number(getExpiresAt()) - Date.now()) <= nearExp
}

export const refreshJwt = async () => await new Promise(resolve => {
    const refreshToken = getRefreshToken()
    if (refreshToken) {
        axios.post<ApiResponse<JWTResponse>>(
            `${appConfig?.backend.server_url}/${apiBase}/${prepareUrl(securityUrls.auth.TOKEN_REFRESH)}`,
            {value: refreshToken},
        )
            .then(res => {
                const t = res?.data?.content;
                setJWT(t?.access_token)
                setRefreshToken(t?.refresh_token)
                setExpiresInSeconds(t?.expires_in)
                resolve(true)
            })
            .catch(
                e => {
                    if (e?.response?.data) {
                        clearTokens()
                        clearLocalUser()
                    }

                    resolve(false)
                }
            )
    } else {
        resolve(false)
    }
})

/*const refreshInterceptor = (config: AxiosRequestConfig) => {
  if (typeof securityUrls.auth[config.url as AuthUrlType] === 'undefined') {
    if (isTokenNearExpiry()) {
      void refreshJwt()
    }
  }

  return config
}

backend.interceptors.request.use(refreshInterceptor)*/
export interface ProgressEventData {
    readonly lengthComputable: boolean
    readonly loaded: number
    readonly total: number
}