
export const securityUrls = {
  auth: {
    REGISTER_USER: '/users',
    LOGIN: '/public/auth/sign-in',
    TOKEN_REFRESH: '/public/iam/user/token/refresh',
    GOOGLE: '/u/social/auth/google',
    FACEBOOK: '/u/social/auth/facebook',
    PHONE: '/u/social/auth/phone',
    FORGOT_PASSWORD: '/u/password/forgot',
    RESET_PASSWORD: '/u/password/reset',
    UPDATE_PERSONAL_USER_INFO: '/users/update-profil',
  }
}

export const backendUrls = {
  users: {
    REGISTER: '/public/iam/user/register',
    UPDATE: '/user/details',
    DETAILS: '/user/details',
    FEED: '/user/feed',
    LOGGED_USER: (userId: number) => `/users/${userId}`,
  },
  comingSoon:{
    NORMAL_USER: '/public/comingSoon/user/',
    SHOP_OWNER: '/public/comingSoon/shopOwner/'
  }
}

export const fileUploadsUrls = {
  UPLOAD: '/files/upload'
}
export const reviewsUrl = {
  REVIEWS: (compId: string) => `/reviews/${compId}`,
}
export const categoriesUrl = {
  CATEGORIES: '/main-categories'
}
export const companyDetailsUrl = {
  COMPANY_DETAILS: (compId: string) => `/companies-details/${compId}`,
}
export const companyAvailabiltyUrl = {
  COMPANYAVAILABILITY: '/companies-availability/',
  BESTSEARCHREQUEST:'/companies-availability/best-search',
  ALLAVAILABLECOMPANY:'/companies-availability/search-request',
  LIKEDCOMPANY:'/companies-availability/likes',
  SEARCHWORD: (wordForSearch:string) => `/companies-availability/${wordForSearch}`,
  SEARCHBYCATEGORY: '/companies-availability/category',
}
export const likesUrl = {
  LIKE_COMPANY: (companyId: string) => `/likes/company/${companyId}`,
}

export const contactMessageUrl = {
  CONTACT_MESSAGE: '/contact-message',
}

export const newsletterUrl = {
  SAVE_EMAIL: (email: string) => `/newsletters/${email}`,
}

export type AuthUrlType = keyof typeof securityUrls.auth
export type UserUrlType = keyof typeof backendUrls.users
