import { configureStore } from '@reduxjs/toolkit'
import type { TypedUseSelectorHook } from 'react-redux'
import {
  useDispatch as useReduxDispatch,
  useSelector as useReduxSelector
} from 'react-redux'
import rootReducer from './rootReducer'
import middlewareArray from './middlewareArray'

export const store = configureStore({
  reducer: rootReducer,
  middleware: defaultMiddleware => defaultMiddleware().concat(middlewareArray)
})

export type RootState = ReturnType<typeof store.getState>

export type AppDispatch = typeof store.dispatch

export const useDispatch = () => useReduxDispatch<AppDispatch>()
export const useSelector: TypedUseSelectorHook<RootState> = useReduxSelector
