import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { ApiErrors } from '../../../../types';
import { AsyncState } from '../../../util';
import { getAllAvailableCompany } from '../../../../pages/searchResult/api';

export const getUser = createAsyncThunk('user/info', () => { })

export const getAllAvailableCompanyAction = createAsyncThunk(
  '/companies-availability/search-request',
  async (params:object ) => {
    const response = await getAllAvailableCompany(params);
    return response.data.content;
  }
);

const initialState: AsyncState<any, ApiErrors> = {
  loading: false
}

const resultSlice = createSlice({
  name: 'searchResult',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder.addCase(getAllAvailableCompanyAction.pending, (state) => {
      state.loading = true;
      state.errors = undefined;
    }).addCase(getAllAvailableCompanyAction.fulfilled, (state, action) => {
      state.loading = false;
      state.payload = action.payload;
      state.errors = undefined;
    }).addCase(getAllAvailableCompanyAction.rejected, (state, action) => {
      state.loading = false;
      state.errors = action.payload as ApiErrors[];
    })
  }
})

export default resultSlice.reducer
