import AppRouter from './routes/Router.component';
import {createTheme, ThemeProvider} from "@mui/material";
import ScrollToTop from "./utils/ScrollToTop";

const App = () => (
    <>
        <ThemeProvider theme={createTheme()}>
            <AppRouter/>
        </ThemeProvider>
    </>
);

export default App
