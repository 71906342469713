const appConfig = {
  backend: {
    server_url: process.env.REACT_APP_API_URL || 'http://localhost:8888' //'https://mbk-pay.herokuapp.com'
  },
  google: {
    places: {
      url: 'https://maps.google.com/maps/api/js',
      key: 'AIzaSyDAjGhClqzQ3fDS3uU12oo0lgIb0Wab6O4'
    }
  },
}

export const appName = 'MBKPAY'

export default appConfig
