import './routerLoader.css'

function RouteLoader () {
	return (
		<div className="loader-container">
			<div className="loader"></div>
		</div>
	);
}

export default RouteLoader
