import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { ApiErrors } from '../../../../types';
import { AsyncState } from '../../../util';
import { sendMessage } from "../../../../pages/contactMessage/api";

export const sendContactMessageAction = createAsyncThunk(
    'send-contact-message',
    async (payload: object) => {
      const response = await sendMessage(payload);
      return response.data.content;
    }
);

const initialState: AsyncState<any, ApiErrors> = {
  loading: false,
  payload: null,
  errors: [],
}

const contactMessageSlice = createSlice({
  name: 'contactMessage',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder.addCase(sendContactMessageAction.pending, (state) => {
      state.loading = true;
      state.errors = undefined;
    }).addCase(sendContactMessageAction.fulfilled, (state, action) => {
      state.loading = false;
      state.payload = action.payload;
      state.errors = undefined;
    }).addCase(sendContactMessageAction.rejected, (state, action) => {
      state.loading = false;
      state.errors = action.payload as ApiErrors[];
    })
  }
})

export default contactMessageSlice.reducer;
