import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { getCompanyDetails } from '../../../../pages/shops/api'
import { AuthUserResponse, ApiErrors } from '../../../../types';
import { AsyncState } from '../../../util';
import { companyDetailsUrl } from '../../../../api/urls';
import { CompanyDetail } from '../../../../domain/domain';

export const getUser = createAsyncThunk('user/info', () => { })


export const getCompanyDetailsAction = createAsyncThunk(
  '/companies-details/{compId}',
  async (compId: string | any) => {
    const response = await getCompanyDetails(compId);
    return response.data.content;
  }
);
const initialState: AsyncState<CompanyDetail, ApiErrors> = {
  loading: false
}
// categories.payload
// categories.loading

const companyDetailsSlice = createSlice({
  name: 'company details',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder.addCase(getCompanyDetailsAction.pending, (state) => {
      state.loading = true;
      state.errors = undefined;
    }).addCase(getCompanyDetailsAction.fulfilled, (state, action) => {
      state.loading = false;
      state.payload = action.payload;
      state.errors = undefined;
    }).addCase(getCompanyDetailsAction.rejected, (state, action) => {
      state.loading = false;
      state.errors = action.payload as ApiErrors[];
    })
  }
})

export default companyDetailsSlice.reducer
