import { Suspense } from 'react'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import type { RouteType } from '../types'
import Guard from './Guard.component'
import RouteLoader from './RouteLoader.component'
import { routes } from './Routes'
import ScrollToTop from "../utils/ScrollToTop";

function AppRouter () {
  const unwrap = (_routes: RouteType[]): any => _routes.map(route => {
    if (route.children != null) {
      // Unwrap
      return (
				<Route key={route.path} path={route.path} element={<Guard route={route} />}>
					{ unwrap(route.children) }
				</Route>
      )
    }

    return <Route key={route.path} path={route.path} element={<Guard route={route} />} />
  })

  return (
		<div className='app'>
			<Router>
				<ScrollToTop />
				<Suspense fallback={<RouteLoader />}>
					<Routes>
						{ unwrap(routes) }
					</Routes>
				</Suspense>
			</Router>
		</div>
  )
}

export default AppRouter
