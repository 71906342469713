import { Appointment } from './../../domain/domain.d';
const translation = {
  welcome: 'Welcome to {{appName}}',
  'sign-in': 'S\'identifier',
  'home-text': 'Accueil',
  'about-us': 'À propos de nous',
  'marketplace-text': 'Place de marché',
  'contact-text': 'Contact',
  'name': 'Nom',
  'email': 'E-mail',
  'address': 'Address',
  'password': 'Mot de passe',
  'confirm-password': 'Confirmer le mot de passe',
  'sign-up': 'S\'inscrire',
  'first-name': 'Prénom',
  'last-name': 'Nom de famille',
  'enterprise': 'Entreprise',
  'shop-owner': 'Propriétaire de la boutique',
  'phone': 'Téléphone',
  'login': 'Connexion',
  'dont-have-account': 'Vous n\'avez pas de compte?',
  'already-have-account': 'Vous avez déjà un compte?',
  'home': 'Home',
  'our-services': 'Nos prestations',
  'service-text': 'MBKPAY vous propose un large choix de services de qualité supérieure, offerts par des professionnels hautement qualifiés, tous spécialisés dans leur domaine de prédilection',
  'hair-styles': 'Styles de cheveux',
  'view-all': 'Voir tout',
  'book-appointment': 'Prendre un rendez-vous',
  'contact': 'Contacter',
  'visit-us': 'Visitez-nous',
  'contact-us': 'Contactez-nous',
  'testimonials': 'Témoignages',
  'add-business': 'Ajouter une entreprise',
  'get-best': 'Obtenez le meilleur',
  'professional': 'Professionnel',
  'beauticians-near-you': 'Esthéticiennes près de chez vous',
  'search-service': 'Que recherchez vous ?',
  'search-location': 'Où ?',
  'logout':'Se déconnecter',
  'setting':'paramètre',
  'dashboard':'tableau de bord',
  'gestion-du-planning':'Gestion du Planning',
  'gestion-des-prestations':'Prestations',
  'total-reservations':'Réservation totale pour un mois',
  'most-requested':'prestations les plus demandées',
  'profile-settings':'paramètre de profil',
  'update':'Mettre à Jour',
  'add-cover': 'Ajouter une couverture',
  'share':'Partager',
  'pre-register':'Pré-inscription',
  'as-a-shop-owner':'En tant que propriétaire de magasin',
  'as-a-shop-client':'en tant que client d\'un magasin',
  'instagram-handle':'Poignée Instagram',
  'shop-name':'Nom de la boutique',
  'services-provided':'Services Fournis',
  'be-the-first-to-know-when':'Soyez le premier informé à la sortie de l’application qui permettra à vous prestataire d’avoir plus de visibilé, et à la clientelle de prendre rendez-vous plus rapidement',
  'all':'Toute',
  'hair-dresser':'Coiffeur',
  'nail-saloon': 'Salon de manucure',
  'spa-and-massage': 'Spa et Massages',
  'skin-care':'Soin de la peau',
  'massage':'Massage',
  'eyebrows-and-lashes':'Sourcils et Cils',
  'more': 'Suite',
  'select-a-lounge':'Sélectionnez un Salon',
  'the-best-saloons':'Les meilleurs salons et instituts autour de vous : Réservation en ligne',
  'book-now':'Resever',
  'book':'resever',
  'reviews':'Avis & Commentaires',
  'opening-hours':'Nos horaires',
  'social-media': 'Rejoignez la communauté',
  'call-us':'Contactez nous',
  'find-service':'Trouvez le prestataire de services de soins personnels parfait pour vous',
  'our-platform':'Notre plateforme en ligne vous permet de trouver et de réserver facilement le prestataire de services de soins personnels idéal pour répondre à vos besoins uniques.',
  'book-online':'Réservez facilement en ligne',
  'book-appiontment':'Prendre rendez vous n\’a jamais été autant facile, on vous permet de réserver facilement chez le prestataire de services de votre choix en quelques clics seulement.',
  'beauty-service':'Vous offrez des services de beauté ?',
  'develop':'Developpez votre activité',
  'platform':'Grace à la plateforme de gestion de vos activités et des rendez-vous que nous mettons à votre disposition, vous serez mieux outillé pour faire grandir votre business',
  'register-business':'Inscrire mon entreprise',
  'my-profile':'Mon Profil',
  'my-favourite':'Mes Préférés',
  'discover':'Découvrir',
  'from':'À partir de',
  'search-result':'Résultat de la recherche',
  'info':'Infos sur mbkpay',
  'propos':'A Propos de  MBKPAY',
  'privacy':'Politique de confidentialité',
  'condition':'Condition générales d\’utilisation',
  'find-us':'Retrouvez Nous',
  'copyrights':'Copyright  2023 mbk-book Tous droits réservés'
















  // not-translated
}
export default translation
