import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import {  getCompanyAvailability, getBestSearchRequest, getSearchResult } from '../../../../pages/shops/api'
import { AuthUserResponse, ApiErrors } from '../../../../types';
import { AsyncState } from '../../../util';

export const getUser = createAsyncThunk('user/info', () => {})


export const getCompanyAvailabilityAction = createAsyncThunk(
  '/companies-availability',
  async () => {
    const response  = await getCompanyAvailability();
    // console.log(response)
    return response.data.content.content
  }
);

export const getBestSearchRequestAction = createAsyncThunk(
  '/companies-availability/search-request',
  async () =>{
    const response = await getBestSearchRequest();
    // console.log(response)
    return response.data.content
  }
);


export const getSearchResultAction = createAsyncThunk(
 '/companies-availability/{wordForSearch}',
  async (wordForSearch: string) => {
    const response = await getSearchResult(wordForSearch);
    return response.data.content;
  }
);



const initialState: AsyncState<any, ApiErrors> = {
  loading: false
}


const companySlice = createSlice({
  name: 'company',
  initialState,
  reducers: {},
  extraReducers (builder) {
    builder.addCase(getCompanyAvailabilityAction.pending, (state) => {
      state.loading = true;
      state.errors = undefined;
    }).addCase(getCompanyAvailabilityAction.fulfilled, (state, action) => {
      state.loading = false;
      state.payload = action.payload;
      state.errors = undefined;
    }).addCase(getCompanyAvailabilityAction.rejected, (state, action) => {
      state.loading = false;
      state.errors = action.payload as ApiErrors[];
    })
  }
})

export default companySlice.reducer
