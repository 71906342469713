import { createSlice } from '@reduxjs/toolkit'

const modals = createSlice({
    name: 'modal',
    initialState: { currentModal: null, payload: null },
    reducers: {
        openModal: (state, action) => {
            state.currentModal = action.payload.modal;
            state.payload = action.payload.data;
        },
        closeModal: (state) => {
            state.currentModal = null;
            state.payload = null
        },
    },
})

export const { openModal, closeModal } = modals.actions

export default modals.reducer
