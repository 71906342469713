import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import {ApiErrors, FullPlace} from '../../../../types';
import { AsyncState } from '../../../util';
import {  getSearchByCategory } from '../../../../pages/searchResult/api';

export const getUser = createAsyncThunk('user/info', () => { })
export const getSearchByCategoryAction = createAsyncThunk(
  'companies-availability-category',
  async(params: object) => {
    const response = await getSearchByCategory(params);
    return response.data.content;
  }
);

const initialState: AsyncState<any, ApiErrors> = {
  loading: false
}

const searchByCategorySlice = createSlice({
  name: 'searchByCategory',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder.addCase(getSearchByCategoryAction.pending, (state) => {
      state.loading = true;
      state.errors = undefined;
    }).addCase(getSearchByCategoryAction.fulfilled, (state, action) => {
      state.loading = false;
      state.payload = action.payload;
      state.errors = undefined;
    }).addCase(getSearchByCategoryAction.rejected, (state, action) => {
      state.loading = false;
      state.errors = action.payload as ApiErrors[];
    })
  }
})

export default searchByCategorySlice.reducer
