import loader from './features/slices/loader/loader.slice'
import user from './features/slices/user'
import categories from './features/slices/categories'
import reviews from './features/slices/reviews'
import companies from './features/slices/companies'
import companyDetails from './features/slices/companyDetails'
import searchResult from './features/slices/searchResult'
import searchByCategory from './features/slices/searchByCategory'
import likes from "./features/slices/likes";
import search from "./features/slices/search";
import modal from "./features/slices/modal";
import menuNavBar from "./features/slices/menuNavBar";
import contactMessage from "./features/slices/contactMessage";

const rootReducer = {
  loader,
  user,
  categories,
  reviews,
  companies,
  companyDetails,
  searchResult,
  searchByCategory,
  likes,
  search,
  modal,
  menuNavBar,
  contactMessage,
}
export default rootReducer
