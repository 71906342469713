import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { getReviews } from '../../../../pages/shops/api'
import { AuthUserResponse, ApiErrors } from '../../../../types';
import { AsyncState } from '../../../util';
import { reviewsUrl } from '../../../../api/urls';

export const getUser = createAsyncThunk('user/info', () => { })
const compId: string = '';

export const getReviewsAction = createAsyncThunk(
  reviewsUrl.REVIEWS(compId),
  async (compId: any) => {
    const response = await getReviews(compId);
    return response.data.content;
  }
);


const initialState: AsyncState<any, ApiErrors> = {
  loading: false,
  payload: null,
  errors: [],
}
// categories.payload
// categories.loading

const reviewSlice = createSlice({
  name: 'reviews',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder.addCase(getReviewsAction.pending, (state) => {
      state.loading = true;
      state.errors = undefined;
    }).addCase(getReviewsAction.fulfilled, (state, action) => {
      state.loading = false;
      state.payload = action.payload;
      state.errors = undefined;
    }).addCase(getReviewsAction.rejected, (state, action) => {
      state.loading = false;
      state.errors = action.payload as ApiErrors[];
    })
  }
})

export default reviewSlice.reducer
