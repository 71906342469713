import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { getCategories, getCompanyAvailability } from '../../../../pages/shops/api'
import { AuthUserResponse, ApiErrors } from '../../../../types';
import { AsyncState } from '../../../util';

export const getUser = createAsyncThunk('user/info', () => {})


export const getCategoriesAction = createAsyncThunk(
  '/mainCategories', 
  async () => {
    const response = await getCategories();
    // console.log(response)
    return response.data.content;
  }
);


const initialState: AsyncState<any, ApiErrors> = {
  loading: false
}
// categories.payload
// categories.loading

const categorySlice = createSlice({
  name: 'category',
  initialState,
  reducers: {},
  extraReducers (builder) {
    builder.addCase(getCategoriesAction.pending, (state) => {
      state.loading = true;
      state.errors = undefined;
    }).addCase(getCategoriesAction.fulfilled, (state, action) => {
      state.loading = false;
      state.payload = action.payload;
      state.errors = undefined;
    }).addCase(getCategoriesAction.rejected, (state, action) => {
      state.loading = false;
      state.errors = action.payload as ApiErrors[];
    })
  }
})

export default categorySlice.reducer
