import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {SearchItem} from "../../../../domain/domain";


interface SearchState {
    searchValue: any | null;
    placeInfo: any | null;
}

const initialState: SearchState = {
    searchValue: null,
    placeInfo: null,
};

export const searchSlice = createSlice({
    name: 'search',
    initialState,
    reducers: {
        setSearchValue: (state, action: PayloadAction<any | null>) => {
            state.searchValue = action.payload;
        },
        setPlaceInfo: (state, action: PayloadAction<any | null>) => {
            state.placeInfo = action.payload;
        },
    },
});

export const { setSearchValue, setPlaceInfo } = searchSlice.actions;

export default searchSlice.reducer;
