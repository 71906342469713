export const path = {
  LANDING: '/',
  LOGIN: '/login',
  LOGIN_MODAL: '/login-modal',
  SIGNUP: '/signup',
  USERPROFILEFORM: '/profile-form',
  UPDATEPROFILE: '/update-profile',
  REGISTER: '/register',
  FILE_UPLOAD: '/file-upload',
  NETWORK_ERROR: '/network-error',
  VALIDATE_EMAIL: '/validate-email',
  MARKET: '/marketplace',
  ABOUT: '/about',
  CONTACT: '/contact',
  BLOG: '/blog',
  ALLARTICLES: '/blog/all-articles',
  INTERNATIONALTRADE: '/blog/international-trade',
  STORAGE: '/blog/storage',
  CULTIVATION: '/blog/cultivation',
  DASHBOARD: '/dashboard',
  SETTING:'/SETTING',
  NEW_SETTINGS: '/new/settings',
  SHOP_DETAILS: 'shop-details',
  SERVICE_SETUP:'/service-setup',
  COMING_SOON: '/coming-soon',
  PRE_REGISTERSHOP: 'pre-registerShop',
  PRE_REGISTERUSER: 'pre-registerUser',
  ALL_SHOPS: 'allShops',
  RESERVATION: '/reservation',
  SHOPSDETAILS: (shopId: string) => `/shopdetails/${shopId}`,
  SEARCHRESULT:'/search-result',
  USERS_OVERVIEW:'/users-overview',
  ACCOUNTSETTING:'/account-setting',
  FAVORITE:'/favorite',
  PRIVACY_POLICY:'/privacy-policy',
  TERMS_CONDITION:'/terms-and-conditions',

}
