import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import {ApiErrors} from '../../../../types';
import {AsyncState} from '../../../util';
import {getLikedCompanies, updateLike} from "../../../../pages/likes/api";
import {getCompanyAvailability} from "../../../../pages/shops/api";
import {getCompanyAvailabilityAction} from "../companies";

export const updateLikeAction = createAsyncThunk(
    '/likes/company/{companyId}',
    async (compId: string) => {
        const response = await updateLike(compId);
        // console.log(response)
        return response.data.content;
    }
);

export const getLikedCompaniesAction = createAsyncThunk(
    '/likes/companies',
    async () => {
        const response = await getLikedCompanies();
        console.log('Liked company : ',response)
        return response.data.content
       }
);

const initialState: AsyncState<any, ApiErrors> = {
    loading: false
}

const likesSlice = createSlice({
    name: 'likes',
    initialState,
    reducers: {},
    extraReducers(builder) {

        builder.addCase(getLikedCompaniesAction.pending, (state) => {
            state.loading = true;
            state.errors = undefined;
        }).addCase(getLikedCompaniesAction.fulfilled, (state, action) => {
            state.loading = false;
            state.payload = action.payload;
            state.errors = undefined;
        }).addCase(getLikedCompaniesAction.rejected, (state, action) => {
            state.loading = false;
            state.errors = action.payload as ApiErrors[];
        })
    }
})

export default likesSlice.reducer;