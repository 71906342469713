import { ApiErrors, ApiResponse } from '../../types';
import { isAxiosError } from '../../types/guards';

export interface AsyncState<T, E = unknown> {
  payload?: T;
  loading: boolean;
  errors?: E[];
}
export const retrieveErrorResponse = (e: unknown): ApiErrors[] | undefined => {
  if (isAxiosError<ApiResponse<unknown>>(e)) {
    return e?.response?.data.errors;
  }
  return undefined;
};
