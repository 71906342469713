import { lazy } from 'react'
import type { RouteType } from '../types'
import { path } from './paths'

export const routes: RouteType[] = [
  {
    path: path.LANDING,
    component: lazy(async () => await import('../pages/landing/landing.page')),
  },
  {
    path: path.LOGIN,
    component: lazy(async () => await import('../pages/auth/login/SignIn')),
  },
  {
    path: path.SIGNUP,
    component: lazy(async () => await import('../pages/auth/sign-up/SignUp')),
  },
  {
    path: path.RESERVATION,
    component: lazy(async () => await import('../pages/reservation/reservation.page')),
  },
  {
    path: path.COMING_SOON,
    component: lazy(async () => await import('../pages/comingSoon/comingSoon.page')),
  },
  {
    path: path.DASHBOARD,
    component: lazy(async () => await import('../pages/dashboard/dashboard.page')),
  },
  {
    path: path.SHOP_DETAILS,
    component: lazy(async () => await import('../pages/shopSetting/ShopDetails.page')),
  },
  {
    path: path.NEW_SETTINGS,
    component: lazy(async () => await import('../pages/shopSetting/Settings.page')),
  },
  {
    path: path.PRE_REGISTERSHOP,
    component: lazy(async () => await import('../pages/comingSoon/PreRegisterShop.page')),
  },
  {
    path: path.PRE_REGISTERUSER,
    component: lazy(async () => await import('../pages/comingSoon/PreRegisterUser.page')),
  },
  {
    path: path.SERVICE_SETUP,
    component: lazy(async () => await import('../pages/shopSetting/ServiceSetup.page')),
  },
  {
    path: path.ALL_SHOPS,
    component: lazy(async () => await import('../pages/shops/AllShops.page')),
  },
  {
    path: '/shopdetails/:shopId',
    component: lazy(async () => await import('../pages/shopDetails/ShopDetails.page')),
  },
  {
    path: path.SEARCHRESULT,
    component: lazy(async () => await import('../pages/searchResult/SearchResult.page')),
  },
  {
    path: path.USERS_OVERVIEW,
    component: lazy(async () => await import('../pages/usersOverview/UsersOverview.page')),
  },
  {
    path: path.FAVORITE,
    component: lazy(async () => await import('../pages/usersOverview/favorites/favorites.page')),
  },
  {
    path: path.CONTACT,
    component: lazy(async () => await import('../pages/contact/contact.page')),
  },
  {
    path: path.ABOUT,
    component: lazy(async () => await import('../pages/aboutUs/aboutUs.page')),
  },
  {
    path: path.PRIVACY_POLICY,
    component: lazy(async () => await import('../pages/privacyPolicy/privacyPolicy.page')),
  },
  {
    path: path.TERMS_CONDITION,
    component: lazy(async () => await import('../pages/termsandcondition/termsandcondition.page')),
  },
  //This should be the last entry, for some reason I prefer it to be so
  {
    path: '*',
    component: lazy(async () => await import('../pages/landing/landing.page')),
  }
]
