import { createSlice, PayloadAction } from '@reduxjs/toolkit';
interface MenuItem {
    menuItem: any | null;
}

const initialState: MenuItem = {
    menuItem: null,
};

export const menuNavBarSlice = createSlice({
    name: 'search',
    initialState,
    reducers: {
        setMenuItem: (state, action: PayloadAction<any | null>) => {
            state.menuItem = action.payload;
        },
    },
});

export const { setMenuItem } = menuNavBarSlice.actions;

export default menuNavBarSlice.reducer;
