const translation = {
  welcome: 'Welcome to {{appName}}',
  'sign-in': 'Sign in',
  'home-text': 'Home',
  'about-us': 'About Us',
  'marketplace-text': 'Marketplace',
  'contact-text': 'Contact',
  'name': 'Name',
  'email': 'Email',
  'address': 'Address',
  'password': 'Password',
  'confirm-password': 'Confirm password',
  'sign-up': 'Sign up',
  'first-name': 'First name',
  'last-name': 'Last name',
  'enterprise': 'Enterprise',
  'shop-owner': 'Shop owner',
  'phone': 'Phone',
  'login': 'Login',
  'search': 'Search',
  'service': 'Our Service',
  'view': 'VIEW ALL',
  'appointments': 'Book Appointments',
  'dont-have-account': 'Dont have an account?',
  'already-have-account': 'Have an account already?',
  'home': 'Home',
  'our-services': 'Our Services',
  'service-text': 'MBKPAY offers you a wide choice of superior quality services, offered by highly qualified professionals, all specialized in their chosen field.',
  'hair-styles': 'Hair Styles',
  'view-all': 'View All',
  'book-appointment': 'Book an Appointment',
  'contact': 'Contact',
  'visit-us': 'Visit Us',
  'contact-us': 'Contact Us',
  'testimonials': 'Testimonials',
  'add-business': 'Add Business',
  'get-best': 'Get the Best',
  'professional': 'Professional',
  'beauticians-near-you': 'Bueaticians near you',
  'search-service': 'Search a service',
  'search-location': 'At (address, town, etc...)',
  'logout':'Logout',
  'setting':'Setting',
  'dashboard':'Dashboard',
  'gestion-du-planning':'Schedule Management',
  'gestion-des-prestations':'Benefits Management',
  'total-reservations':'Total Reservations For a month',
  'most-requested':'Most requested services',
  'profile-settings':'Profile Setting',
  'update':'UPDATE',
  'add-cover': 'ADD COVER',
  'share':'Share',
  'pre-register':'Pre-register',
  'as-a-shop-owner':'As a shop Owner',
  'as-a-shop-client':'As a shop Client',
  'instagram-handle':'Instagram Handle',
  'shop-name':'Shop Name',
  'services-provided':'Services Provided',
  'be-the-first-to-know-when':'Be the first to know when you leave the application, which will allow your service provider to have more visibility, and customers to make appointments more quickly',
  'all':'All',
  'hair-dresser':'Hair Dresser',
  'nail-saloon': 'Nail Saloon',
  'spa-and-massage': 'Spa and Massage',
  'skin-care':'Skin Care',
  'massage':'Massage',
  'eyebrows-and-lashes':'Eyebrows and Lashes',
  'more': 'More',
  'select-a-lounge':'Select a Lounge',
  'the-best-saloons':'The best Saloons and institute around: Online Booking',
  'book-now':'Book Us Now',
  'book':'Book Now',
  'reviews':'Reviews',
  'opening-hours':'Opening hours',
  'social-media':'Social media handles',
  'call-us':'Call us',
  'find-service':'Find the perfect personal care service provider for you',
  'our-platform':'Our online platform makes it easy to find and book the perfect personal care service provider to meet your unique needs.',
  'book-online':'Book easily online',
  'book-appiontment':'Making an appointment has never been easier, we allow you to easily book with the service provider of your choice in just a few clicks.',
  'beauty-service':'Do you offer beauty services?',
  'develop':'Grow your business',
  'platform':'Thanks to the platform for managing your activities and appointments that we make available to you, you will be better equipped to grow your business.',
  'register-business':'Register my business',
  'my-profile':'My profile',
  'my-favourite':'My Favourite',
  'discover':'Discover',
  'from':'From',
  'searchresult':'Search result',
  'info':'Infos on mbkpay',
  'propos':'About MBKPAY',
  'privacy':'Privacy Policy',
  'condition':'Terms and Conditions',
  'find-us':'Find us',
  'copyrights':'Copyright 2023 mbkpay All Rights Reserved'
  // not-translated
}
export default translation
