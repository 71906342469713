import {securedBackend, unsecuredBackend} from "../../../api";
import { categoriesUrl, reviewsUrl, companyAvailabiltyUrl, companyDetailsUrl } from "../../../api/urls";
import { CompanyDetail } from "../../../domain/domain";
import { ApiResponse } from "../../../types";


export const getCategories = () => unsecuredBackend
     .get(categoriesUrl.CATEGORIES);

export const getReviews = (compId: string) => unsecuredBackend
     .get(reviewsUrl.REVIEWS(compId));

export const getCompanyDetails = (compId: string) => unsecuredBackend
     .get<ApiResponse<CompanyDetail>>(companyDetailsUrl.COMPANY_DETAILS(compId));
     
export const getCompanyAvailability = () => securedBackend
     .get(companyAvailabiltyUrl.COMPANYAVAILABILITY);

export const getBestSearchRequest = () => unsecuredBackend
     .get(companyAvailabiltyUrl.BESTSEARCHREQUEST);

export const  getSearchResult = (wordForSearch:string) => unsecuredBackend
     .get(companyAvailabiltyUrl.SEARCHWORD(wordForSearch));
