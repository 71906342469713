import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import {isTokenNearExpiry, refreshJwt} from '../../../../api'
import {ApiErrors, JWTResponse, RegisterCompanyRequest, SignUpUserRequest, userForm} from '../../../../types'
import {
    setExpiresInSeconds, setJWT, setLocalUser, setRefreshToken
} from '../../../../storage/local.storage'
import type {
    AuthUserResponse,
    LoginUserRequest,
    User,
} from '../../../../types'
import {AsyncState, retrieveErrorResponse} from '../../../util'
import {
    getLoggedUser,
    loginUser,
    registerUser,
    updateProfileUser
} from '../../../../pages/auth/api'
import {UserInfo} from "../../../../domain/domain";
import {AxiosError} from "axios";

export const getUserActions = createAsyncThunk('user/info', async (userId: number, thunk) => {
    const loggedUserResponse = await getLoggedUser(userId);
    return loggedUserResponse?.data?.content;
})

const postAuthActions = (payload: AuthUserResponse) => {
    const {jwtResponse} = payload;
    // Save the token in local storage
    setJWT(jwtResponse?.access_token);
    setRefreshToken(jwtResponse?.refresh_token);
    setExpiresInSeconds(jwtResponse?.expires_in);
    setLocalUser(payload);
}
export const loginUserAction = createAsyncThunk('user/login', async (creds: LoginUserRequest, thunk) => {
    try {
        const authResponse = await loginUser(creds);
        authResponse?.data?.content && postAuthActions(authResponse?.data?.content);
        return authResponse?.data?.content;
    }
    catch (error: any){
        return error
    }


})

export const registerUserAction = createAsyncThunk('user/register', async (payload: userForm, thunk) => {
    try {
        const response = await registerUser(payload);
        response?.data?.content && postAuthActions(response.data.content);
        return response.data.content;
    } catch (e) {
        return thunk.rejectWithValue(retrieveErrorResponse(e));
    }
})

export const updateProfileUserAction = createAsyncThunk(
    'user/update/profile',
    async (payload: userForm, thunk) => {
        try {
            const response = await updateProfileUser(payload);
            return response.data.content;
        } catch (e) {
            return thunk.rejectWithValue(retrieveErrorResponse(e));
        }
    })

export const loadUserAction = createAsyncThunk('user/load', async () => {
    // Check if token has expired
    if (isTokenNearExpiry()) {
        await refreshJwt()
    }
})


type UserState = {
    loginUser: AsyncState<AuthUserResponse | null, ApiErrors>;
    getUser: AsyncState<UserInfo | null, ApiErrors>;
}

const initialState: UserState = {
    loginUser: {
        loading: false,
        payload: null,
        errors: undefined,
    },
    getUser: {
        loading: false,
        payload: null,
        errors: undefined,
    },
}

const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {},
    extraReducers(builder) {
        builder.addCase(loginUserAction.pending, (state) => {
            state.loginUser.loading = true;
            state.loginUser.errors = undefined;
        }).addCase(loginUserAction.fulfilled, (state, action) => {
            state.loginUser.loading = false;
            state.loginUser.payload = action.payload;
            state.loginUser.errors = undefined;
        }).addCase(loginUserAction.rejected, (state, action) => {
            state.loginUser.loading = false;
            state.loginUser.errors = action.payload as ApiErrors[];
        })
            .addCase(registerUserAction.pending, (state) => {
                state.loginUser.loading = true;
                state.loginUser.errors = undefined;
            }).addCase(registerUserAction.fulfilled, (state, action) => {
            state.loginUser.loading = false;
            state.loginUser.payload = action.payload;
            state.loginUser.errors = undefined;
        }).addCase(registerUserAction.rejected, (state, action) => {
            state.loginUser.loading = false;
            state.loginUser.errors = action.payload as ApiErrors[];
        })
            .addCase(getUserActions.pending, (state) => {
                state.getUser.loading = true;
                state.getUser.errors = undefined;
            }).addCase(getUserActions.fulfilled, (state, action) => {
            state.getUser.loading = false;
            state.getUser.payload = action.payload;
            state.getUser.errors = undefined;
        }).addCase(getUserActions.rejected, (state, action) => {
            state.getUser.loading = false;
            state.getUser.errors = action.payload as ApiErrors[];
        })
    }
})

export default userSlice.reducer

